<template>
  <div v-if="show">
    <div class="modal-backdrop fade show">
    </div>
    <div class="modal fade show" tabindex="-1" style="display:block">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header pb-0 mb-2 text-center">
            <button type="button" class="btn-close" aria-label="Close" @click="close"></button>
            <h2 class="modal-title">{{$t('mnemonicsave')}}</h2>
          </div>
          <div class="modal-body">
            <div class="form__body mb-3">
              <div class="form-item mb-3">
                <label for="accountPassword" class="form__label d-block">{{$t('pwdlabel')}}</label>
                <input type="password" class="form__control pwd" id="accountPassword"
                       v-model="pwdm" :placeholder="$t('pwdph')" @keyup="decryptMnemonic"
                       autocomplete="password" required>
                <span @click="showPwd">
                  <svg class="form__control-icon"><use xlink:href="#i-eye"></use></svg>
                </span>
              </div>
              <div v-show="seed">
                <div class="form-item mb-3">
                  <label for="accountMnemonic" class="form__label d-block">{{$t('mnemonicsavetip')}}</label>
                  <input class="form__control pwd" id="accountMnemonic" :value="seed" readonly>
                  <span @click="copy(seed)">
                    <svg class="form__control-icon"><use xlink:href="#i-copy"></use></svg>
                  </span>
                </div>
                <div class="form-item mb-3">
                  <label class="check">
                    <input type="checkbox" class="check__control sr-only" v-model="saved" required/>
                    <span class="check__icon"></span>
                    <span class="check__text">{{$t('mnemonicsaved')}}</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="form__foot">
              <div class="row justify-content-center">
                <div class="col-8">
                  <button class="btn btn-md btn-primary form__submit w-100" @click="close">
                    {{$t('closebtn')}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {readModalShow} from '@/store/main/getters';
import {commitToggleModal} from '@/store/main/mutations';

export default {
  name: 'ModalMnemonic',
  data() { return {
    pwdm: '',
    mnemonic: localStorage.mnemonic || false,
    seed: '',
    saved: false,
  }},
  computed: {
    show() {
      const modalShow = readModalShow(this.$store)
      return modalShow === 'mnemonic';
    },
  },
  methods: {
    close() {
      if (!this.saved) return false;
      commitToggleModal(this.$store, '');
      location.reload();
    },
    checkPwd(d) {
      return (!(typeof (d) != 'string' || !/^[A-Za-z0-9@]{8,}$/.test(d)));
    },
    copy(d) {
      if (typeof(d) !== 'string' || !d.length) return false;
      this.copyToClipboard(d);
      this.$noty.success(this.$t('copied') || '');
    },
    decrypt(data, pwd) {
      if (typeof(data) !== 'string' || !this.checkPwd(pwd)) return this.$noty.error(this.$t('pwdtip'), { timeout: 2000, progressBar: true });
      try {
        return this.fromHex(this.CryptoJS.AES.decrypt(data, pwd).toString());
      } catch(e) {
        return false;
      }
    },
    showPwd() {
      const pwds = document.querySelectorAll('input.pwd');
      if (!pwds) return false;
      pwds.forEach(p=>{
        let type = p.getAttribute('type');
        if (type === 'password') p.setAttribute('type','text');
        else p.setAttribute('type','password');
      });
    },
    decryptMnemonic() {
      if (!this.checkPwd(this.pwdm)) return false;
      this.seed = this.decrypt(localStorage.mnemonic, this.pwdm);
      /*console.log('seed', this.seed);
      console.log('seed local', this.mnemonic);
      console.log('seed locale', localStorage.mnemonic);
      console.log('pwd', this.pwdm);*/
      if (this.seed) return true;
      this.seed = '';
      this.$noty.error(this.$t('wrongPwd'), { timeout: 2000, progressBar: true });
    },
  },

}
</script>

<style scoped>

</style>
