<template>
  <div v-if="show">
    <div class="modal-backdrop fade show">
    </div>
    <div class="modal fade show" tabindex="-1" style="display:block">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header pb-0 mb-0 text-center">
            <button type="button" class="btn-close" aria-label="Close" @click="close"></button>
            <h2 class="modal-title mb-3">{{$t('recovery_account')}}</h2>
          </div>
          <div class="modal-body pt-2 pb-4">
            <div class="form__body mb-3">
              <div class="form-item mb-3">
                <label for="accountEmail" class="form__label d-block">{{$t('unlabel')}}</label>
                <input type="email" class="form__control" id="accountEmail" v-model="un"
                       :placeholder="$t('unph')" autocomplete="username" required/>
              </div>
              <div class="form-item mb-3">
                <label for="accountPasswordConfirm" class="form__label d-block">{{$t('secret_phrase')}}</label>
                <textarea style="height:65px" type="password" class="form__control pwd" id="accountPasswordConfirm"
                       v-model="seed" placeholder="Секретная фраза" autocomplete="confirm-password"
                       required></textarea>
              </div>
            </div>
            <div class="form__foot">
              <div class="row justify-content-center">
                <div class="col-8">
                  <button class="btn btn-md btn-primary form__submit w-100"
                          @click="goRecovery">
                    {{$t('proceedbtn')}}
                  </button>
              </div>
              <div class="row justify-content-center text-center mt-3">
                <div class="col-12 mb-2">
                  {{$t('isreged')}} <a class="link hand" @click="login">{{$t('loginbtn')}}</a>
                </div>
                <div class="col-12">
                  <a class="link hand" @click="signup">{{$t('signupbtn')}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import axios from 'axios';
import {readModalShow, readUser, readUserFp} from '@/store/main/getters';
import {commitSetUser, commitToggleModal} from '@/store/main/mutations';

export default {
  name: 'ModalRecovery',

  data() { return {
    un: '',
    seed: ''
  }},
  computed: {
    show() {
      const modalShow = readModalShow(this.$store)
      return modalShow === 'recovery';
    },
    user() {
      return readUser(this.$store);
    },
  },
  methods: {
    close() {
      commitToggleModal(this.$store, '');
    },
    login() {
      commitToggleModal(this.$store, 'login');
    },
    signup() {
      commitToggleModal(this.$store, 'signUp');
    },
    goRecovery() {

      this.un = this.un.toLowerCase().trim();

      if(this.un == '' || this.seed == '') {
        this.$noty.error(this.$t('insert_email_and_twofactor'), { timeout: 2000, progressBar: true });
        return;
      }

      axios.post(this.$store._modules.root.state.main.API_URL+'/user',
        {method: 'goRecovery',
          data:{
            un:this.un, fp:readUserFp(this.$store), seed: this.seed}}).then( r => {
          console.log(r.data);
           if (r.data.code ===0) {
            this.$noty.error(this.$t(r.data.data.error||'smthwrong'), { timeout: 4000, progressBar: true });
          }
          else if (r.data.code === 1 && typeof r.data.data === 'object') {
            localStorage.un = this.username;
            localStorage.uid = r.data.data.uid;
            localStorage.hash = r.data.data.hash;
            localStorage.isHasPin = r.data.data.pin;
            commitSetUser(this.$store, {
                ref: '',
                un: this.username,
                hash: r.data.data.hash,
                uid: r.data.data.uid,
                isHasPin: r.data.data.pin,
            });
            this.$noty.success(this.$t('account_recovered'), { timeout: 2000, progressBar: true });
            // spa.component.auth = true;
            // this.close();
            location.reload();
          }
      });

    },
  },
  created() {
  },
}
</script>

<style scoped>

</style>
