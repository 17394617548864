<template>
  <div v-if="show">
    <div class="modal-backdrop fade show"  @click="close">
    </div>
    <div class="modal fade show" tabindex="-1" style="display:block">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header pb-0 mb-2 text-center">
            <button type="button" class="btn-close" aria-label="Close" @click="close"></button>
            <h2 class="modal-title mb-3" v-show="modalType !== 'cancel'">{{$t('transfer_go')}} {{symbol.toUpperCase()}}</h2>
            <h2 class="modal-title" v-show="modalType === 'cancel'">{{$t('transfer_cancel')}}</h2>
          </div>
          <div class="modal-body" v-if="modalType !== 'cancel'">
            <div class="tabs" v-if="!transferSuccess" >
              <!-- <div class="tabs__head">
                <ul class="nav nav-tabs">
                  <li class="nav-item"><button class="nav-link active" type="button">
                    {{$t('externalsource')}}
                  </button></li>
                  <li class="nav-item"><button class="nav-link" type="button">Between Ocean</button></li>
                </ul>
              </div> -->
              <div class="tab-content">
                <div class="tab-pane fade show active">

                  <div class="form__body mb-3" v-if="stepActive == 1">
                    <div class="form-item mb-2 mt-1 asset">
                      <label class="form__label d-block asset__label text-center mb-2">{{$t('balance')}}</label>
                      <div class="asset__inner d-flex align-items-center justify-content-center">
                        <div class="cryptocurrency">
                          <img class="cryptocurrency__img" :src="logo"/>
                          <div class="cryptocurrency__name ms-2">{{coinBalance.amount}} {{symbol.toUpperCase()}}</div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="form-item mb-3">
                        <label for="transferMethod" class="form__label d-block">
                          <i class="fas fa-info-circle"></i> {{$t('transfer_type')}}
                        </label>
                        <select id="transferMethod" @change="transferMethodChange($event)" class="form__control"
                               v-model="transfer.method" required>
                               <option value="payid" :selected="true" selected>Pay ID</option>
                               <option value="email">Email</option>
                        </select>
                      </div>
                      <div class="form-item mb-3">
                        <label for="transferWallet" class="form__label d-block">
                          <i class="fas fa-info-circle"></i> {{ transferMethodName }}
                        </label>
                        <input type="text" id="transferWallet" class="form__control"
                               v-model="transfer.wallet" :placeholder="transferMethodName" required/>
                              <span v-if="adrError" style="color: #FF5860;">{{ $t('wrongAdr') }}</span>
                      </div>
                      <div class="form-item mb-3">
                        <label for="transferAmount" class="form__label d-block">
                          <i class="fas fa-info-circle"></i> {{$t('amount')}}
                        </label>
                        <input type="text" class="form__control" id="transferAmount"
                               v-model="transfer.amount" :placeholder="$t('amountph')" @keyup="calcBase"
                               required/>
                        <span v-if="sumError" style="color: #FF5860;">{{ $t('minAmount') }} 1 {{symbol.toUpperCase()}}<br>{{$t('transfer_max_sum')}}: 10 000 {{symbol.toUpperCase()}}</span>
                        <div class="mt-1">
                          <span class="text-grey40">{{$t('baseamount')}}</span>
                          {{base.amount}} {{symbol.toUpperCase()}}
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-center mt-3">
                      <div class="col-8">
                        <button type="submit" class="btn btn-md btn-primary form__submit w-100"
                                @click="defaultButton" :disabled="disabled">
                          {{$t('transfer_go')}}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="form__body mb-3" v-if="stepActive == 2 || stepActive == 3">

                    <div class="wd-info">
                      <div class="wd-info-item is-flex">
                        <div class="wd-info-item-left">
                          {{$t('sum')}}
                        </div>
                        <div class="wd-info-item-right">
                          {{ transfer.amount }} {{symbol.toUpperCase()}}
                        </div>
                      </div>
                      <div class="wd-info-item is-flex">
                        <div class="wd-info-item-left">
                          {{$t('transfer_type')}}
                        </div>
                        <div class="wd-info-item-right">
                          {{ transferMethodMiniName }}
                        </div>
                      </div>
                      <div class="wd-info-item is-flex">
                        <div class="wd-info-item-left">
                          {{$t('transfer_rekv')}}
                        </div>
                        <div class="wd-info-item-right">
                          {{ transfer.wallet }}
                        </div>
                      </div>
                    </div>
                    <ul class="wd-warn" v-if="stepActive == 2" v-html="this.$t('transfer_warn')">
                    </ul>
                     <button type="button" class="btn btn-md btn-primary form__submit w-100"
                              @click="confirmButton" v-if="stepActive == 2">
                        {{$t('transfer_next')}}
                      </button> 

                      <div class="mt-5" v-if="stepActive == 3">

                        <div v-if="sendProccess == 0">
                          <label for="pin2email" class="form__label d-block">
                              <i class="fas fa-info-circle"></i> {{$t('pin2email')}}
                            </label>
                            <input type="text" class="form__control" id="pin2email"
                                   style="width: 60%; margin-right: 10px;"
                                   v-model="transfer.pin2email" :placeholder="$t('pin2emailph')"/>
                            <button type="button" class="btn btn-gray-outline wallet__btn"
                                      :disabled="sendCodeTimer > 0"
                                    style="display: inline-flex; width: 36%; text-align: center; justify-content: center;"
                                    @click="pin2faMail">
                              <span v-if="sendCodeTimer > 0">
                                {{ sendCodeTimer }} сек.<br>
                                <span style="font-size: 7px;">{{$t('send_repeat')}}</span>
                              </span>
                              <span v-else>{{$t('send')}}</span>
                            </button>
                            <span v-if="pin2emailError" style="color: #c3445a;">{{$t('invalid_email')}}</span>

                          <div class="form-item mt-3" v-if="status2fa == 1">
                            <label for="login2FA" class="form__label d-block">{{$t('google_code')}}</label>
                            <input type="text" id="login2FA" class="form__control pwd" v-model="facode" autocomplete="password" required>
                            </div>
                          </div>

                          <div class="row justify-content-center mt-3">
                            <div class="col-8" v-if="sendProccess == 0">
                              <button type="submit" class="btn btn-md btn-primary form__submit w-100"
                              @click="submitButton" :disabled="disabled">
                              {{$t('transfer_confirm')}}
                            </button>
                          </div>
                          <div class="col-8" v-if="sendProccess == 1">
                              <button type="submit" class="btn btn-md btn-primary form__submit w-100" style="background:red"
                              @click="cancelButton" :disabled="disabled">
                              {{$t('cancel_btn')}} ({{ sendCoinTimer }} сек.)
                            </button>
                          </div>
                        </div>

                      </div>


                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="text-center">
                <img src="@/assets/img/mail_sent.jpg" style="width: 70px; margin-bottom: 10px;">

                <h2>{{$t('transfer_success')}}</h2>
              </div>
              <div class="row mt-3 mb-3">
                <div class="col-4" style="color: #494b7c;">
                  {{$t('transfer_quantity')}}
                </div>
                <div class="col-8 text-right">
                  {{ transfer.amount }} <span style="color: #494b7c;">{{symbol.toUpperCase()}}</span>
                </div>
              </div>
               <div class="row mb-3 mt-3">
                <div class="col-4" style="color: #494b7c;">
                  {{$t('transfer_type')}}
                </div>
                <div class="col-8 text-right">
                  {{ transferMethodMiniName }}
                </div>
              </div>
              <div class="row mb-3 mt-3">
                <div class="col-4" style="color: #494b7c;">
                  {{$t('transfer_rekv')}}
                </div>
                <div class="col-8 text-right">
                  {{ transfer.wallet }}
                </div>
              </div>
            </div>
          </div>
          <div v-else class="modal-body">
            <p class="modal__text mb-1">{{$t('transfer_sure')}} {{transfer.amount}} {{symbol.toUpperCase()}}?</p>
            <div class="row justify-content-center mt-3">
              <div class="col-8">
                <button type="submit" class="btn btn-md btn-primary form__submit w-100"
                        @click="defaultButton">
                   {{$t('cancel_btn')}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {api} from '@/api.ts';

import logoUSDT from '@/assets/img/coins/usdt.svg';
import logoSTAB from '@/assets/img/coins/stab.svg';
import logoREND from '@/assets/img/coins/rend.svg';
import {readModalShow, readSymbol, readUser, readUserFp} from '@/store/main/getters';
import {commitToggleModal, commitSetCoins} from '@/store/main/mutations';
import {dispatchGetUser} from '@/store/main/actions';
import axios from 'axios';
import VueQRCodeComponent from 'vue-qr-generator';
import {readCoins} from "../../store/main/getters";

export default {
  name: 'Modaltransfer',
  data() { return {
    base: { symbol: 'USDT', amount: 0 },
    coin: {},
    commission: {
      to_transfer_amount: 0,
      commission_amount: 0,
    },
    balance: {},
    transfer: { amount: 0, wallet: '', pin2email: '', pin2fa: '', method: 'payid', required: { pin2email: false, pin2fa: false }},
    disabled: false,
    sendCodeTimer: 0,
    sendCoinTimer: 0,
    transferSuccess: false,
    adrError: false,
    sumError: false,
    pin2emailError: false,
    pin2faError: false,
    modalType: 'create',
    need2fa: 0,
    facode: '',
    stepActive: 1,
    status2fa: 0,
    transferMethodName: this.$t('insert_payid_title'),
    bnext: true,
    transferMethodMiniName: 'Pay ID',
    sendProccess: 0
  }},
  computed: {
    show() {
      this.disabled = false;
      this.sendCodeTimer = 0;
      this.sendCoinTimer = 0;
      this.transferSuccess = false;
      this.walletError = false;
      this.sumError = false;
      this.pin2emailError = false;
      this.pin2faError = false;
      this.transfer.pin2email = '';
      this.transfer.pin2fa = '';
      this.transfer.amount = '';
      this.transfer.wallet = '';

      const modalShow = readModalShow(this.$store);
      if(!modalShow.data) {
        this.transfer.amount = '';
        this.transfer.wallet = '';
        this.disabled = false;
        this.sendCodeTimer = 0;
        this.sendCoinTimer = 0;
        this.transferSuccess = false;
        this.walletError = false;
        this.sumError = false;
        this.pin2emailError = false;
        this.pin2faError = false;
        this.transfer.pin2email = '';
        this.transfer.pin2fa = '';
        this.modalType = 'create';
        this.transfer.required.pin2email = false;
        this.transfer.required.pin2fa = false;
        return modalShow === 'transfer';
      }

      this.transfer.wallet = modalShow.data.wallet;
      this.transfer.amount = modalShow.data.amount;
      this.commission.to_transfer_amount = modalShow.data.amount;
      this.commission.commission_amount = '1';
      this.modalType = modalShow.data.modalType;
      this.transfer.required.pin2email = true;
      return modalShow.modal === 'transfer';
    },
    symbol() {
      return readSymbol(this.$store);
    },
    coinBalance() {
      const coins = readCoins(this.$store);
      let coin = {
        amount: 0,
        frozen: 0,
        id: 0,
        rate: 0,
        symbol: '',
      };
      console.log(`getting coinBalance of "${this.symbol.toLowerCase()}"`);
      console.log(coins);
      for (const [key, value] of Object.entries(coins)) {
        console.log(value.symbol.toLowerCase(), this.symbol.toLowerCase());
        if (value.symbol.toLowerCase() === this.symbol.toLowerCase()) {
          coin = value;
        }
      }
      console.log(coin);
      return coin;
    },
    logo() {
      if (this.symbol === 'usdt') {
        return logoUSDT;
      } else if (this.symbol === 'stab') {
        return logoSTAB;
      } else if (this.symbol === 'rend') {
        return logoREND;
      }
    },
    user() {
      return readUser(this.$store);
    },
  },
  watch: {
    symbol: function (newShow, oldShow) {
      console.log('symbol changed');
      dispatchGetUser(this.$store);
      this.getBalance();
    },
  },
  methods: {
    close() {
      commitToggleModal(this.$store, '');
      if(this.transferSuccess || this.sendProccess == 1) {
        location.reload();
      }
      this.stepActive = 1;
    },
    async calcBase() {
      await this.calcCommission()
      this.base.amount = (parseFloat(this.transfer.amount || 0) * Number(this.coinBalance.rate) || 0).toFixed(4);
    },
    calcCommission() {
      let payout = 1.00;
      this.commission.commission_amount = payout;

      // if (this.base.symbol !== 'USDT') {
      //   payout = Number(this.coin.payout) || 1;
      //   this.commission.commission_amount = (parseFloat(this.transfer.amount || 0) * (payout / 100 )).toFixed(4);
      // }
      this.commission.to_transfer_amount = (parseFloat(this.transfer.amount || 0) - this.commission.commission_amount).toFixed(4);
    },
    checkAuth() {
      if (this.user.hash) return this.auth = true;
      commitToggleModal(this.$store, 'login');
      return false;
    },
    getBalance() {
      if (!this.checkAuth()) return false;
      this.balance = {};
      console.log(`getBalance for symbol ${this.symbol}`);
      axios.post(this.$store._modules.root.state.main.API_URL+'/wallet',
          {method: 'getBalance', data:{symbol: this.symbol, fp: readUserFp(this.$store), uid: this.user.uid}},
          {headers: {'Authorization':'Bearer ' + this.user.hash}}).then(r=>{
        console.log(r.data);
        if (r.data.code === 1) return this.$noty.error((this.$t(r.data.data.error) || this.$t('smthwrong'))+(r.data.data.msg||''), { timeout: 2000, progressBar: true });
        if (r.data.code !== 2 || typeof(r.data.data) !== 'object') return false;
        this.coin = r.data.data.coin;
        this.balance = r.data.data.balance;
      });
    },
    resendCodeTimer() {
      if (this.sendCodeTimer > 0) {
        this.sendCodeTimer -= 1
        setTimeout(() => { this.resendCodeTimer() }, 1000);
      }
    },

    endCoinTimer() {
      if (this.sendCoinTimer > 0) {
        this.sendCoinTimer -= 1
        setTimeout(() => { this.endCoinTimer() }, 1000);
      } else if(this.sendCoinTimer == 0) {
        this.createtransfer(true)
      }
    },

    defaultButton(){
      if (!this.checkAuth()) return false;

      if(!this.transfer.wallet) {
        this.adrError = true;
        //return this.$noty.error(this.$t('wrongAdr'), { timeout: 2000, progressBar: true });
      } else {
        this.adrError = false;
      }

      api('wallet', 'transferCheckUser', {wallet: this.transfer.wallet})
      .then( r => {
        if(!r.data.ok) {
          this.$noty.error(this.$t('invalid_user'), { timeout: 2000, progressBar: true });
        } else {

          if (this.transfer.amount < 1 || this.transfer.amount > 10000) {
            this.sumError = true;
            return this.$noty.error(this.$t('wrongAmount'), { timeout: 2000, progressBar: true })
          } else {
            this.sumError = false;
          };

          this.coinBalance.amount = parseFloat(this.coinBalance.amount||0);
          if (this.coinBalance.amount < this.transfer.amount) {
            this.sumError = true;
            return this.$noty.error(this.$t('lowBalance'), { timeout: 2000, progressBar: true })
          } else {
            this.sumError = false;
          };

          this.stepActive = 2;


        }
      })

    },

    confirmButton() {
      this.stepActive = 3
    },

    submitButton() {
      this.createtransfer()
    },

    createtransfer(proccessed = false) {
      this.transfer.wallet = (this.transfer.wallet||'').trim();
      if (this.transfer.wallet.length < 1) {
        this.walletError = true;
        return this.$noty.error(this.$t('wrongAdr'), { timeout: 2000, progressBar: true });
      } else {
        this.walletError = false;
      }

      if (this.transfer.amount < 1 || this.transfer.amount > 10000) {
        this.sumError = true;
        return this.$noty.error(this.$t('wrongAmount'), { timeout: 2000, progressBar: true })
      } else {
        this.sumError = false;
      };

      this.coinBalance.amount = parseFloat(this.coinBalance.amount||0);
      if (this.coinBalance.amount < this.transfer.amount) {
        this.sumError = true;
        return this.$noty.error(this.$t('lowBalance'), { timeout: 2000, progressBar: true })
      } else {
        this.sumError = false;
      };

      api('wallet', 'transferCoinNew', {amount: this.transfer.amount, payee: this.transfer.wallet, symbol: this.symbol, facode: this.facode, pin2mail: this.transfer.pin2email, proccessed: proccessed})
      .then( r => {
        console.log('transferCoin create', r);

        // check email
        if(r.data.error == 'pinRequired') {
          this.$noty.error(this.$t('insert_email'), { timeout: 2000, progressBar: true });
          return;
        }
        if(r.data.error == 'pinWrong') {
          this.$noty.error(this.$t('invalid_email'), { timeout: 2000, progressBar: true });
          return;
        }

        // check 2fa
        if(r.data.error == '2faRequired') {
          this.$noty.error(this.$t('confirm_transfer_with_google'), { timeout: 2000, progressBar: true });
          this.need2fa = 1;
          return;
        }
        if(r.data.error == '2faWrong') {
          this.$noty.error(this.$t('invalid_auth_with_google'), { timeout: 2000, progressBar: true });
          return;
        }

        if(proccessed == false) {
          this.sendCoinTimer = 10;
          this.endCoinTimer();
          this.sendProccess = 1;
        }

        if(proccessed == true) {
          if (r.code === 0) return this.$noty.error((this.$t(r.data.error) || this.$t('smthwrong')) +' '+ (r.data.msg || ''), { timeout: 4000, progressBar: true });
          if (r.code === 1) return this.$noty.error((this.$t(r.data.error) || this.$t('smthwrong')) +' '+ (r.data.msg || ''), { timeout: 4000, progressBar: true });
          if (r.code !== 2 || typeof(r.data) !== 'object') return false;

          /*this.sendCodeTimer = 60;
          this.resendCodeTimer();
          this.transfer.required.pin2email = true;
          this.modalType = 'confim';*/

          this.$noty.success(this.$t('transfer_success'), { timeout: 4000, progressBar: true });
          this.transferSuccess = true;
          this.updateCoins(this.symbol, Number(r.data.balance));
        }
      });
    },

    pin2faMail() {
      if (!this.checkAuth()) return false;

      api('wallet', 'sendEmailPin', {type: 'pin2fa', symbol: this.symbol.toUpperCase(), amount: this.transfer.amount, payee: this.transfer.wallet, mode: this.transferMethodMiniName, func: 'transfer'})
      .then( r => {
        console.log('transferCoin pin2fa', r);
        
        if(r.data.ok) {
          this.$noty.info(this.$t('email_pin_sent'), { timeout: 2000, progressBar: true });

          this.sendCodeTimer = 60;
          this.resendCodeTimer();
        } else {
          this.$noty.error(this.$t('alert_error'), { timeout: 2000, progressBar: true });
        }

        
      });
    },

    updateCoins(symbol, amount){
      const coins = readCoins( this.$store );

      const result = coins.map(item => {
        if(item.symbol.toLowerCase() === symbol.toLowerCase()) item.amount = amount;
        return item
      });
      commitSetCoins( this.$store, result );
    },

    loadProfile(){
      axios.post(this.$store._modules.root.state.main.API_URL+'/user',
          {method: 'loadProfile',
            data: { fp: readUserFp(this.$store), uid: this.user.uid}},{headers: {'Authorization':'Bearer ' + this.user.hash}}).then(r => {
        //console.log('loadProfile', r.data);
        if (typeof(r.data.data.profile)=='object') this.status2fa = r.data.data.profile.pin;
      });
    },

    transferMethodChange(event) {
      if(event.target.value == 'payid') {
        this.transferMethodName = this.$t('insert_payid_title');
        this.transferMethodMiniName = 'Pay ID';
      } else if(event.target.value == 'email') {
        this.transferMethodName = this.$t('insert_email_title');
        this.transferMethodMiniName = 'Email';
      }
    },

    cancelButton() {
      this.close();
      //this.sendCoinTimer = -1;
    },

  },
  created() {
    dispatchGetUser(this.$store);
    this.getBalance();
    this.loadProfile()
  },
}
</script>

<style scoped>

</style>
