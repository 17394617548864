<template>
  <div v-if="show">
    <div class="modal-backdrop fade show">
    </div>
    <div class="modal fade show" tabindex="-1" style="display:block">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header pb-0 mb-2 text-center">
            <button type="button" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close" @click="close"></button>
            <h2 class="modal-title">{{$t('login')}}</h2>
            <p class="modal__text">{{$t('loginurl')}}</p>
            <div class="correct-url">
              <span class="correct-url__https">https://</span>
              <span class="correct-url__link">{{origin}}</span>
            </div>
          </div>
          <div class="modal-body">
            <form>
              <div class="form__body mb-3">
                <div v-if="need2fa == 0 && needmail == 0">
                  <div class="form-item mb-3">
                    <label for="loginEmail" class="form__label d-block">{{$t('unlabel')}}</label>
                    <input type="email" id="loginEmail" class="form__control" v-model="username"
                           :placeholder="$t('unph')" autocomplete="username" required>
                  </div>
                  <div class="form-item">
                    <label for="loginPassword" class="form__label d-block">{{$t('pwdlabel')}}</label>
                    <input type="password" id="loginPassword" class="form__control pwd" v-model="password"
                           :placeholder="$t('pwdph')" autocomplete="password" required>
                    <span @click="showPwd">
                      <svg class="form__control-icon"><use xlink:href="#i-eye"></use></svg>
                    </span>
                  </div>
                </div>
                <div class="form-item mt-3" v-if="need2fa == 1">
                  <label for="login2FA" class="form__label d-block">{{$t('google_code')}}</label>
                  <input type="text" id="login2FA" class="form__control pwd" v-model="facode" autocomplete="password" required>
                </div>
                <div class="form-item mt-3" v-if="needmail== 1">
                  <label for="loginMail" class="form__label d-block">{{$t('login_insert_pin')}}</label>
                  <input type="text" class="form__control" id="loginMail"
                         style="width: 60%; margin-right: 10px;"
                         v-model="mailcode" :placeholder="$t('pin2emailph')"/>
                  <button type="button" class="btn btn-gray-outline wallet__btn"
                            :disabled="sendCodeTimer > 0"
                          style="display: inline-flex; width: 36%; text-align: center; justify-content: center;"
                          @click="pin2faMail">
                    <span v-if="sendCodeTimer > 0">
                      {{ sendCodeTimer }} {{$t('sec')}}<br>
                      <span style="font-size: 7px;">{{$t('send_repeat')}}</span>
                    </span>
                    <span v-else>{{$t('send')}}</span>
                  </button>
                </div>
                <div class="row justify-content-center mt-3">
                  <div class="col-8">
                    <button type="button" class="btn btn-md btn-primary form__submit w-100" @click="authUser">
                      <i class="fas fa-sign-in-alt"></i>&nbsp;{{$t('loginbtn')}}
                    </button>
                  </div>
                </div>
              </div>
              <div class="form__foot" v-if="need2fa == 0 && needmail == 0">
                <div class="login-with-account">
                  <h2 class="login-with-account__title">{{$t('loginexternal')}}</h2>
                  <div class="login-with-account__btns">
                    <div class="row">
                      <div class="col-6">
                        <a href="#" class="login-with-account__btn">
                          <div class="login-with-account__pict me-1">
                            <img class="login-with-account__img"
                                 src="@/assets/img/logos/logo-between-ocean.svg" alt="">
                          </div>
                          <span class="login-with-account__btn-text">Between Ocean</span>
                        </a>
                      </div>
                      <div class="col-6">
                        <a href="#" class="login-with-account__btn">
                          <div class="login-with-account__pict me-2">
                            <img class="login-with-account__img"
                                 src="@/assets/img/logos/logo-stability.png" alt="">
                          </div>
                          <span class="login-with-account__btn-text">Stability</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center text-center mt-3">
                  <div class="col-12 mb-2">
                    <a class="link me-3 hand" @click="signup">
                      <i class="fas fa-user-plus"></i>&nbsp;{{$t('signup')}}
                    </a>
                    <a class="link ms-3 hand" @click="forgot">
                      <i class="fab fa-expeditedssl"></i>&nbsp;{{$t('forgotpwd')}}
                    </a>
                    <a class="link ms-3 hand" @click="recovery" style="display:inline-block; margin-top:8px">
                      <i class="fab fa-expeditedssl"></i>&nbsp; {{$t('recovery_account')}}
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {readModalShow, readUserFp} from '@/store/main/getters';
import {commitSetUser, commitToggleModal} from '@/store/main/mutations';

export default {
  name: 'LoginModal',
  data() { return {
    username: '',
    password: '',
    origin: 'between.kdo.name',
    need2fa: 0,
    facode: '',
    needmail: 0,
    mailcode: '',
    sendCodeTimer: 0,
    gRecaptcha: ''
  }},
  computed: {
    show() {
      const modalShow = readModalShow(this.$store)
      return modalShow === 'login';
    },
  },
  methods: {
    close() {
      commitToggleModal(this.$store, '');
    },
    checkEmail(d) {
      return (!(typeof (d) !== 'string' || !/^([A-Za-z0-9-_\.]{1,})@([A-Za-z0-9-]{3,})\.([a-z]{2,10})$/.test(d)));
    },
    checkPwd(d) {
      return (!(typeof (d) !== 'string' || !/^[A-Za-z0-9@]{8,}$/.test(d)));
    },
    showPwd() {
      const pwds = document.querySelectorAll('input.pwd');
      if (!pwds) return false;
      pwds.forEach(p => {
        let type = p.getAttribute('type');
        if (type === 'password') p.setAttribute('type', 'text');
        else p.setAttribute('type', 'password');
      });
    },
    authUser(e) {
      if (e !== undefined) e.preventDefault();
      this.createRecaptcha();
      this.username = this.username.toLowerCase().trim();
      // if (!this.checkEmail(this.username)) return this.$swal({title: this.$t('warning'),text: this.$t('wrongUsername'), icon:'warning'});
      if (!this.checkPwd(this.password)) return this.$noty.error(this.$t('pwdtip'), { timeout: 4000, progressBar: true });
      //this.$noty.info(this.$t('loading'), { timeout: 2000, progressBar: true });
      axios.post(this.$store._modules.root.state.main.API_URL+'/user',
        {method: 'userAuth',
          data: {un:this.username, fp: readUserFp(this.$store), pwd: this.CryptoJS.MD5(this.password).toString(), facode: this.facode, mailcode: this.mailcode, gRecaptcha: this.gRecaptcha}}).then(r=>{

          //console.log(r.data);

          if (r.data.data.error) {
            if(r.data.data.error.includes('2faRequired') || r.data.data.error.includes('MailVerifyRequired')) {

              if(r.data.data.error.includes('2faRequired')) {
                //this.$noty.error('Подтвердите вход при помощи Google Authenticator', { timeout: 2000, progressBar: true });
                this.need2fa = 1;
              }

              if(r.data.data.error.includes('MailVerifyRequired')) {
                //this.$noty.error('Подтвердите вход при помощи Email', { timeout: 2000, progressBar: true });
                this.needmail = 1;
              }

              return;

            }
            
            if(r.data.data.error.includes('2faWrong')) {
              this.$noty.error(this.$t('invalid_auth_with_google'), { timeout: 2000, progressBar: true });
              return;
            }

            
            if(r.data.data.error.includes('MailVerifyWrong')) {
              this.$noty.error(this.$t('invalid_email'), { timeout: 2000, progressBar: true });
              return;
            }
          }

          if (r.data.code ===0) {
            this.$noty.error(this.$t(r.data.data.error||'smthwrong'), { timeout: 4000, progressBar: true });
          }
          else if (r.data.code === 1 && typeof r.data.data === 'object') {
            localStorage.un = this.username;
            localStorage.uid = r.data.data.uid;
            localStorage.hash = r.data.data.hash;
            localStorage.isHasPin = r.data.data.pin;
            commitSetUser(this.$store, {
                ref: '',
                un: this.username,
                hash: r.data.data.hash,
                uid: r.data.data.uid,
                isHasPin: r.data.data.pin,
            });
            this.$noty.success(this.$t('access'), { timeout: 2000, progressBar: true });
            // spa.component.auth = true;
            // this.close();
            location.reload();
          }
      });
    },
    signup() {
      commitToggleModal(this.$store, 'signUp');
    },
    forgot() {
      commitToggleModal(this.$store, 'forgot');
    },
    recovery() {
      commitToggleModal(this.$store, 'recovery');
    },
    pin2faMail() {
      axios.post(this.$store._modules.root.state.main.API_URL+'/user',
        {method: 'sendEmailPin',
          data: {un:this.username, fp: readUserFp(this.$store), pwd: this.CryptoJS.MD5(this.password).toString(), mailcode: this.mailcode}}).then(r=>{
        //console.log('lgoin pin2fa', r);
        
        if(r.data.data.ok) {
          this.$noty.info(this.$t('email_pin_sent'), { timeout: 2000, progressBar: true });

          this.sendCodeTimer = 60;
          this.resendCodeTimer();
        } else {
          this.$noty.error(this.$t('alert_error'), { timeout: 2000, progressBar: true });
        }

        
      });
    },
    resendCodeTimer() {
      if (this.sendCodeTimer > 0) {
        this.sendCodeTimer -= 1
        setTimeout(() => { this.resendCodeTimer() }, 1000);
      }
    },
    createRecaptcha() {
      var $this = this;
      grecaptcha.ready(function() {
        //console.log('RECAPTCHA!');
        grecaptcha.execute('6LcmhVwnAAAAANaiRZ0olvXhvg7918c0lIX0D95y', {action: 'submit'}).then(function(token) {
          //console.log(token);
            $this.gRecaptcha = token;
        });
      });
    }
  },
  created() {
    // if (window.CryptoJS==undefined) fn.asyncLoad('https://cdnjs.cloudflare.com/ajax/libs/crypto-js/4.0.0/crypto-js.min.js');
    this.createRecaptcha()
  },
}
</script>

<style scoped>

</style>
