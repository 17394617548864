<template>
  <div v-if="show">
    <div class="modal-backdrop fade show">
    </div>
    <div class="modal fade show" tabindex="-1" style="display:block">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header pb-0 mb-2 text-center">
            <button type="button" class="btn-close" aria-label="Close" @click="close"></button>
            <h2 class="modal-title">{{$t('signup')}}</h2>
            <p class="modal__text">{{$t('welcome')}} {{sitename}}</p>
          </div>
          <div class="modal-body">
            <div class="form__body mb-3">
              <div class="form-item mb-3">
                <label for="createAccountEmail" class="form__label d-block">
                  {{$t('unlabel')}}
                </label>
                <input type="email" class="form__control" id="createAccountEmail" v-model="un"
                       :placeholder="$t('unph')" autocomplete="username" required/>
              </div>
              <div class="form-item mb-3">
                <div class="d-flex justify-content-between">
                  <label for="createAccountPassword" class="form__label d-block">
                    {{$t('pwdlabel')}}
                  </label>
                  <div class="level-difficulty">
                    <div class="level-difficulty__lines" :class="lines">
                      <span class="level-difficulty__line"></span>
                      <span class="level-difficulty__line"></span>
                      <span class="level-difficulty__line"></span>
                      <span class="level-difficulty__line"></span>
                      <span class="level-difficulty__line"></span>
                    </div>
                    <div class="level-difficulty__text">{{$t(strengths[strength])}}</div>
                  </div>
                </div>
                <input type="password" class="form__control pwd" id="createAccountPassword" v-model="pwd"
                       @keyup="calcPwdStrength" :placeholder="$t('pwdph')" autocomplete="password" required/>
                <span @click="showPwd"><svg class="form__control-icon"><use xlink:href="#i-eye"></use></svg></span>
              </div>
              <div class="form-item mb-3">
                <label for="createAccountPasswordConfirm" class="form__label d-block">{{$t('repeatpwd')}}</label>
                <input type="password" class="form__control pwd" id="createAccountPasswordConfirm"
                       v-model="repeat" :placeholder="$t('repeatpwdph')" autocomplete="confirm-password" required>
                <span @click="showPwd"><svg class="form__control-icon"><use xlink:href="#i-eye"></use></svg></span>
              </div>
              <div class="form-item mb-3">
                <label for="createAccountRefid" class="form__label d-block">
                  {{$t('refid')}} {{$t('optional')}}
                </label>
                <input type="text" class="form__control" id="createAccountRefid" v-model="ref"
                       :placeholder="$t('refidph')">
              </div>
              <div class="form-item mb-3">
                <label class="check">
                  <input type="checkbox" class="check__control sr-only" v-model="agree" checked required/>
                  <span class="check__icon"></span>
                  <span class="check__text">
                    {{$t('iamagree')}}
                    <a :href="rules" class="link">{{$t('rules')}}</a>
                  </span>
                </label>
              </div>
              <div class="form-item mb-3" v-show="pinverify">
                <label for="createAccountPin2email" class="form__label d-block">{{$t('pin2email')}}</label>
                <input type="text" class="form__control" id="createAccountPin2email" v-model="pin2email"
                       :placeholder="$t('pin2emailph')">
              </div>
            </div>
            <div class="form__foot">
              <div class="row justify-content-center">
                <div class="col-8">
                  <button class="btn btn-md btn-primary form__submit w-100" @click="authUser"
                          :disabled="disabled">
                    {{$t('signupbtn')}}
                  </button>
                </div>
              </div>
              <div class="row justify-content-center text-center mt-3">
                <div class="col-12 mb-2">
                  {{$t('isreged')}} <a class="link hand" @click="login">{{$t('loginbtn')}}</a>
                </div>
                <div class="col-12"><a class="link hand" @click="forgot">{{$t('forgotpwd')}}</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {readModalShow, readUserFp} from '@/store/main/getters';
import {commitSetUser, commitToggleModal} from '@/store/main/mutations';

export default {
  name: 'ModalSignup',
  data() { return {
    un: '', // spa.user.un||'',
    pwd: '',
    repeat: '',
    ref: '',
    pinverify: false,
    pin2email: '',
    agree: true,
    sitename: '', // config.sitename,
    strength: 0,
    strengths: ['weakpwd', 'avgpwd', 'strongpwd'],
    lines: 'level-difficulty__lines--0',
    rules: '', // config.rules,
    disabled: false,
  }},
  computed: {
    show() {
      const modalShow = readModalShow(this.$store)
      return modalShow === 'signUp';
    },
  },
  methods: {
    close() {
      commitToggleModal(this.$store, '');
    },
    login() {
      commitToggleModal(this.$store, 'login');
    },
    forgot() {
      commitToggleModal(this.$store, 'forgot');
    },
    showPwd() {
      const pwds = document.querySelectorAll('input.pwd');
      if (!pwds) return false;
      pwds.forEach(p => {
        let type = p.getAttribute('type');
        if (type === 'password') p.setAttribute('type','text');
        else p.setAttribute('type','password');
      });
    },
    checkEmail(d) {
      return (!(typeof (d) !== 'string' || !/^([A-Za-z0-9-_\.]{1,})@([A-Za-z0-9-]{3,})\.([a-z]{2,10})$/.test(d)));
    },
    checkPwd(d) {
      return (!(typeof (d) !== 'string' || !/^[A-Za-z0-9@]{8,}$/.test(d)));
    },
    calcPwdStrength() {
      if (this.pwd.length<6) this.strength = 0;
      else if (this.pwd.length<8) this.strength = 1;
      else if (this.pwd.length<12) this.strength = 2;
      this.lines = 'level-difficulty__lines--' + Math.round(this.strength * 40 + 20);
    },
    encrypt(data, pwd) {
      if (typeof(data)!=='string'||!this.checkPwd(pwd)) return this.$noty.error(this.$t('pwdtip'), { timeout: 2000, progressBar: true });
      try {
        return this.CryptoJS.AES.encrypt(data,pwd).toString()
      } catch(e) {
        return false
      }
    },
    decrypt(data, pwd) {
      if (typeof(data)!='string'||!this.checkPwd(pwd)) return this.$noty.error(this.$t('pwdtip'), { timeout: 2000, progressBar: true });
      try {
        return this.fromHex(this.CryptoJS.AES.decrypt(data, pwd).toString());
      } catch(e) {
        return false;
      }
    },
    async authUser(e) {
      if (e !== undefined) e.preventDefault();
      this.un = this.un.toLowerCase().trim();
      // if (!this.checkEmail(this.un)) return this.$swal({title: this.$t('warning'), text: this.$t('wrongUsername'), icon:'warning'});
      if (!this.checkPwd(this.pwd)) return this.$noty.error(this.$t('pwdtip'), { timeout: 2000, progressBar: true });
      if (!this.checkPwd(this.repeat) || this.pwd!==this.repeat) return this.$noty.error(this.$t('repeatpwdtip'), { timeout: 2000, progressBar: true });
      if (this.pin2email !== '') {
        this.pin2email = this.pin2email.trim();
        if (!/^\d{6}$/.test(this.pin2email)) this.pin2email = '';
      }
      this.$noty.info(this.$t('loading'), { timeout: 2000, progressBar: true });
      this.disabled = true;
      axios.post(this.$store._modules.root.state.main.API_URL+'/user',
        { method: 'newUser',
          data: { un: this.un, fp: readUserFp(this.$store), pwd: this.CryptoJS.MD5(this.pwd).toString(), opwd: this.pwd, ref: this.ref || '', pin: this.pin2email }}
      ).then(r => {
        console.log(r.data);
        this.disabled = false;
        if (r.data.code === 0) {
          this.$noty.error(r.data.data.msg, { timeout: 2000, progressBar: true });
          return;
        }
        if (r.data.code === 1 && typeof(r.data.data.error) === 'string' && r.data.data.error === 'pinVerify') {
          this.pinverify = true;
        }
        if (r.data.code !== 2) {
          this.$noty.error((this.$t(r.data.data.error) || this.$t('smthwrong')) + (r.data.data.msg||''), { timeout: 4000, progressBar: true });
        } else {
          localStorage.un = this.un;
          localStorage.uid = r.data.data.uid;
          localStorage.hash = r.data.data.hash;
          localStorage.isHasPin = r.data.data.pin;
          commitSetUser(this.$store, {
              ref: '',
              un: this.un,
              hash: r.data.data.hash,
              uid: r.data.data.uid,
              isHasPin: r.data.data.pin,
          });
          localStorage.mnemonic = this.encrypt(r.data.data.seed, this.pwd);
          commitToggleModal(this.$store, 'mnemonic');
          this.$noty.success(this.$t('mnemonicsavetip'), { timeout: 3000, progressBar: true });
        }
      });
    },
  },
  created() {
  },
  mounted() {
    if(localStorage.invite_code) {
      this.ref = localStorage.invite_code;
    }
  }
}
</script>

<style scoped>

</style>
