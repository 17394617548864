<template>
  <div v-if="show">
    <div class="modal-backdrop fade show">
    </div>
    <div class="modal fade show" tabindex="-1" style="display:block">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header pb-0 mb-4 text-center">
            <button type="button" class="btn-close" aria-label="Close" @click="close"></button>
            <h2 class="modal-subtitle mb-2">{{$t('logouttitle')}}</h2>
            <p class="modal__text mb-0">{{$t('logoutconfirm')}}</p>
          </div>
          <div class="modal-body pt-2 pb-4">
            <div class="row justify-content-center">
              <div class="col-8">
                <button class="btn btn-md btn-primary w-100" @click="logout">
                  <i class="fas fa-sign-out-alt"></i> {{$t('logout')}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {readModalShow} from '@/store/main/getters';
import {commitSetUser, commitToggleModal} from '@/store/main/mutations';

export default {
  name: 'ModalLogout',
  data() { return {
  }},
  computed: {
    show() {
      const modalShow = readModalShow(this.$store)
      return modalShow === 'logout';
    },
  },
  methods: {
    close() {
      commitToggleModal(this.$store, '');
    },
    logout() {
      localStorage.un = '';
      localStorage.uid = '';
      localStorage.hash = '';
      localStorage.isHasPin = false;
      commitSetUser(this.$store, {
          ref: '',
          un: '',
          hash: '',
          uid: '',
          isHasPin: false,
      });
      window.location.href = '/';
    },
  },
}
</script>

<style scoped>

</style>
