<template>
  <div v-if="show">
    <div class="modal-backdrop fade show"  @click="close">
    </div>
    <div class="modal fade show" tabindex="-1" style="display:block">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header pb-0 mb-2 text-center">
            <button type="button" class="btn-close" aria-label="Close" @click="close"></button>
            <h2 class="modal-title mb-3" v-show="modalType !== 'cancel'">{{$t('withdrawtitle')}} {{symbol.toUpperCase()}}</h2>
          </div>

          <div class="modal-body">
            
            <div class="wcheck flex">
              <button class="wcheck-btn btn btn-info" @click="openWithdrawModal('withdraw')">{{$t('withdraw_normal_btn')}}</button>
              <button class="wcheck-btn btn btn-info" @click="openWithdrawModal('withdrawStab')" v-if="issetStabID">{{$t('withdraw_stab_btn')}}</button>
            </div>

          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {api} from '@/api.ts';

import logoUSDT from '@/assets/img/coins/usdt.svg';
import logoSTAB from '@/assets/img/coins/stab.svg';
import logoREND from '@/assets/img/coins/rend.svg';
import {readModalShow, readSymbol, readUser, readUserFp} from '@/store/main/getters';
import {commitToggleModal, commitSetCoins} from '@/store/main/mutations';
import {dispatchGetUser} from '@/store/main/actions';
import axios from 'axios';
import VueQRCodeComponent from 'vue-qr-generator';
import {readCoins} from "../../store/main/getters";

export default {
  name: 'ModalWithdrawChoose',
  data() { return {
    profile: [],
    issetStabID: false
  }},
  computed: {
    show() {


      const modalShow = readModalShow(this.$store);
      if(!modalShow.data) {
        this.modalType = 'withdrawChoose';
        return modalShow === 'withdrawChoose';
      }
      return modalShow.modal === 'withdrawChoose';
    },
    symbol() {
      return readSymbol(this.$store);
    },
    logo() {
      if (this.symbol === 'usdt') {
        return logoUSDT;
      } else if (this.symbol === 'stab') {
        return logoSTAB;
      } else if (this.symbol === 'rend') {
        return logoREND;
      }
    },
    user() {
      return readUser(this.$store);
    },
  },
  watch: {
    symbol: function (newShow, oldShow) {
      console.log('symbol changed');
      dispatchGetUser(this.$store);
      this.getBalance();
    },
  },
  methods: {
    openWithdrawModal(modal) {
      console.log('modal', modal);
      commitToggleModal( this.$store, modal);
    },
    close() {
      commitToggleModal(this.$store, '');
      this.stepActive = 1;
    },
    checkAuth() {
      if (this.user.hash) return this.auth = true;
      commitToggleModal(this.$store, 'login');
      return false;
    },

    loadProfile(){
      axios.post(this.$store._modules.root.state.main.API_URL+'/user',
          {method: 'loadProfile',
            data: { fp: readUserFp(this.$store), uid: this.user.uid}},{headers: {'Authorization':'Bearer ' + this.user.hash}}).then(r => {
        //console.log('loadProfile', r.data);
        if (typeof(r.data.data.profile)=='object') this.status2fa = r.data.data.profile.pin;
        this.profile = r.data.data.profile;
        if(this.profile.stab_id) {
          this.issetStabID = true;
        }
      });
    },

  },
  created() {
    dispatchGetUser(this.$store);
    this.loadProfile()
  },
}
</script>

<style scoped>
  
</style>
