<template>
  <div v-if="show">
    <div class="modal-backdrop fade show">
    </div>
    <div class="modal fade show" tabindex="-1" style="display:block">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header pb-0 mb-0 text-center">
            <button type="button" class="btn-close" @click="close"></button>
            <h2 class="modal-title mb-2">{{$t('deposittitle')}}</h2>
            <!-- <p class="modal__text mb-1">{{$t('depositsource')}} {{balance.public}}</p> -->
          </div>
          <div class="modal-body">
            <div class="tabs">
              <!-- <div class="tabs__head">
                <ul class="nav nav-tabs">
                  <li class="nav-item" v-for="item in platform.nav" :key="item.id">
                    <button :class="{active: item.id === platform.active}" @click="platform.active = item.id" class="nav-link" type="button">{{item.name}}</button>
                  </li>
                </ul>
              </div> -->
              <div class="tab-content">
                <div class="tab-pane fade show active">
                    <div class="form__body mb-3">
                      <div class="form-item mb-2 mt-1 asset">
                        <label class="form__label d-block asset__label text-center mb-2">{{$t('balance')}} {{symbolUpper}}</label>
                        <div class="asset__inner d-flex align-items-center justify-content-center">
                          <div class="cryptocurrency">
                            <img class="cryptocurrency__img" :src="logo"/>
                            <div class="cryptocurrency__name ms-2">{{coinBalance.amount}} {{symbolUpper}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="alert align-items-start d-none">
                        <svg class="alert__icon">
                          <use xlink:href="#i-warning"></use>
                        </svg>
                        <div class="alert__text">
                          <p class="mb-2">
                            {{$t('deposittip1')}} {{symbol}}
                            <span class="d-block mt-1 text-grey40">{{$t('deposittip2')}}</span>
                          </p>
                          <p class="mb-0 ">
                            {{$t('depositmin')}} {{coin.min}} {{symbol}}.
                            <span class="d-block mt-1 text-grey40">
                              {{$t('depositmintip1')}} {{coin.min}} {{symbol}} {{$t('depositmintip2')}}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div class="form-item mb-4 mt-3">
                        <label for="sendBitcoinAddress" class="form__label text-grey40 d-block" v-if="symbol != 'usdt'">
                          {{$t('depositadrtip')}}
                        </label>
                        <label for="sendBitcoinAddress" class="form__label text-grey40 d-block" v-if="symbol == 'usdt'">
                          {{$t('send_usdt_to_address')}}
                        </label>
                        <input type="text" class="form__control hand" @click="copy(balance.public)"
                               :placeholder="$t('adrph')" :value="balance.public" style="cursor: pointer" readonly/>
                         <svg class="form__control-icon" @click="copy(balance.public)">
                            <use xlink:href="#i-copy"></use>
                          </svg>
                      </div>
                      <div class="form-item mb-3">
                        <div class="row justify-content-center">
                          <div class="col-6">
                            <div class="qr-code">
                              <img class="qr-code__img d-block w-100 d-none"
                                   :src="coinBalance.qr">
                              <vue-q-r-code-component
                                  v-if="balance.public"
                                  :text="balance.public"
                                  :size="180"
                                  color="#000000"
                                  bg-color="#fff"
                                  error-level="H">
                              </vue-q-r-code-component>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="form-item mb-3" v-if="symbol == 'usdt'">
                        <div class="wd-info wd-info-dep">
                          <div class="wd-info-item is-flex">
                            <div class="wd-info-item-left">
                              {{$t('await_income')}}
                            </div>
                            <div class="wd-info-item-right">
                              {{$t('confirm_net')}}: <b>1</b>
                            </div>
                          </div>
                          <div class="wd-info-item is-flex">
                            <div class="wd-info-item-left">
                              {{$t('min_income_sum')}}
                            </div>
                            <div class="wd-info-item-right">
                              <b>0.00000001</b> USDT
                            </div>
                          </div>
                        </div>
                        <ul class="wd-warn" v-html="this.$t('min_sum_warn')">
                        </ul>
                      </div>

                      <div class="row justify-content-center mt-3">
                        <div class="col-8">
                          <button class="btn btn-md btn-primary form__submit w-100" :disabled="disabled" @click="checkReplenish">
                          <i class="fas fa-sync-alt"></i> {{$t('checkbtn')}}
                          </button>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import logoUSDT from '@/assets/img/coins/usdt.svg';
import logoSTAB from '@/assets/img/coins/stab.svg';
import logoREND from '@/assets/img/coins/rend.svg';
import {readModalShow, readSymbol, readUser, readUserFp} from '@/store/main/getters';
import {commitToggleModal} from '@/store/main/mutations';
import VueQRCodeComponent from 'vue-qr-generator';
import {dispatchGetUser} from "@/store/main/actions";
import {readCoins} from "../../store/main/getters";
import {commitSetCoins} from "../../store/main/mutations";

export default {
  components: {
    VueQRCodeComponent,
  },
  name: 'ModalDeposit',
  data() { return {
    coin: {},
    balance: {},
    disabled: false,
    platform: {
      active: 0,
      nav: [
        {
          id: 0,
          name: this.$t('externalsource')
        },
        {
          id: 1,
          name: 'Between Ocean'
        }
      ]
    }
  }},
  computed: {
    show() {
      const modalShow = readModalShow(this.$store);
      return modalShow === 'deposit';
    },
    symbol() {
      return readSymbol(this.$store);
    },
    coinBalance() {
      const coins = readCoins(this.$store);
      let coin = {
        amount: 0,
        frozen: 0,
        id: 0,
        rate: 0,
        symbol: '',
        public: '',
        qr: '',
      };

      for (const [key, value] of Object.entries(coins)) {
        if (value.symbol.toLowerCase() === this.symbol.toLowerCase()) {
          coin = value;
        }
      }
      return coin;
    },
    logo() {
      if (this.symbol === 'usdt') {
        return logoUSDT;
      } else if (this.symbol === 'stab') {
        return logoSTAB;
      } else if (this.symbol === 'rend') {
        return logoREND;
      }
    },
    symbolUpper() {
      return readSymbol(this.$store).toUpperCase();
    },
    user() {
      return readUser(this.$store);
    },

    platformResult() {
      const result = Object.values(this.platform.nav).filter(item => item.id === this.platform.active);
      return result[0].name;
    }
  },
  methods: {
    close() {
      commitToggleModal(this.$store, '');
    },
    copy(d) {
      this.copyToClipboard(d);
      this.$noty.success(this.$t('copied') || '');
    },
    checkAuth() {
      if (this.user.hash) return this.auth = true;
      commitToggleModal(this.$store, 'login');
      return false;
    },
    getBalance() {
      this.balance = {};
      axios.post(this.$store._modules.root.state.main.API_URL+'/wallet',
          {method: 'getBalance', data:{symbol: this.symbol, fp: readUserFp(this.$store), uid: this.user.uid}},
          {headers: {'Authorization':'Bearer ' + this.user.hash}}).then(r=>{
        console.log(r.data);
        if (r.data.code === 1) return this.$noty.error((this.$t(r.data.data.error) || this.$t('smthwrong'))+(r.data.data.msg||''), { timeout: 2000, progressBar: true });
        if (r.data.code !== 2 || typeof(r.data.data) !== 'object') return false;
        this.coin = r.data.data.coin;
        this.balance = r.data.data.balance;
      });
    },
    getCoins() {
      const modalShow = readModalShow(this.$store);
      if (modalShow === 'deposit') {
        this.disabled = true;
        this.$noty.info(this.$t('wait'), {timeout: 3000, progressBar: true});
        axios.post(this.$store._modules.root.state.main.API_URL+'/wallet',
            {method: 'getCoins', data: {fp: readUserFp(this.$store), uid: this.user.uid}},
            {headers: {'Authorization': 'Bearer ' + this.user.hash}}).then(r => {
              this.disabled = false;
          console.log(r.data);
          if (r.data.code === 1) return this.$noty.error((this.$t(r.data.data.error) || this.$t('smthwrong')) + (r.data.data.msg || ''), {
            timeout: 4000,
            progressBar: true
          });
          if (r.data.code !== 2 || typeof (r.data.data) !== 'object') return false;

          commitSetCoins(this.$store, r.data.data.coins);
          this.$noty.info(this.$t('balance') +' '+ this.coinBalance.amount, {timeout: 3000, progressBar: true});
        });
      }
    },
    checkReplenish() {
      this.disabled = true;
      this.getCoins();
    },
  },
  created() {
    dispatchGetUser(this.$store);
    this.getBalance();
  },
  mounted() {
    
  }
}
</script>

<style scoped>

</style>
